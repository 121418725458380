export function initCookiebotFbPixel() {

    window.addEventListener('CookiebotOnAccept', function (e) {
        if (Cookiebot.consent.marketing) {
            const templateContent = document.querySelector("#cookiebot-marketing").content;
            document.head.appendChild(templateContent.cloneNode(true));
        }
    }, false);

}
