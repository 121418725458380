
export function initAnalytics() {
    window.dataLayer = window.dataLayer || [];
    window.addEventListener('DOMContentLoaded', () => {
        addEvent('pageView', { url: window.location.href });
    });
    initPreRegistrationForm();
}

function initPreRegistrationForm() {
    const forms = [...document.querySelectorAll('form')].filter(form => form.id.includes('preRegistration'));
    for (const form of forms) {
        let touched = false;
        form.addEventListener('change', e => {
            if (! touched) {
                touched = true;
                addEvent('preRegistrationFormStart');
                addFbEvent('mw-eloreg');
            }
        });
    }
}

export function addEvent(eventName, miscData = null) {
    if (miscData) {
        window.dataLayer.push({ event: eventName, ...miscData });
    } else {
        window.dataLayer.push({ event: eventName });
    }
}

export function addFbEvent(eventName) {
    if (window.fbq && typeof window.fbq === 'function') {
        fbq('trackCustom', eventName, {content_name: eventName});
    }
}